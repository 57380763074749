import clsx from "clsx";
import { useData } from "../context/data";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import common_axios from "../utils/common_axios";

let IMG_PATH = process.env.REACT_APP_IMG_PATH_PROD;
const origin = window.location.origin;

if (origin === "https://closet-stage.mysochials.com") {
  IMG_PATH = process.env.REACT_APP_IMG_PATH;
}
if (origin === "https://closet.mysochials.com") {
  IMG_PATH = process.env.REACT_APP_IMG_PATH_PROD;
}
// process.env.NODE_ENV === "production"
//   ? process.env.REACT_APP_IMG_PATH_PROD
//   : process.env.REACT_APP_IMG_PATH;

export default function MarkCoordinates() {
  const containerRef = useRef(null);
  const { challenges, placeholders, closets, fetchPlaceholders } = useData();
  const [selectedChallenge, setSelectedChallenge] = useState({});
  const [coordinates, setCoordinates] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [newDataWithLocation, setNewDataWithLocation] = useState({});
  const [loading, setLoading] = useState(false);
  const handleDivClick = (e) => {
    console.log(e.nativeEvent);
    const obj = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    };
    console.log(obj);
    setCoordinates(obj);
    setIsOpen(true);
  };

  const handleOptionSelect = (e) => {
    const selectedData = e.target.value;
    // alert(`${coordinates.x}, ${coordinates.y}`);
    const newDeviceWithLocationData = {
      ...newDataWithLocation,
      [selectedData]: {
        x: coordinates.x / 414,
        y: coordinates.y / 538,
      },
    };
    setNewDataWithLocation(newDeviceWithLocationData);
    setIsOpen(false);
  };

  const handleDragStart = (e, key) => {
    // Store the initial position of the mouse
    e.dataTransfer.setData("key", key);
  };

  const handleDrag = (e) => {
    // Prevent the default behavior for smoother dragging
    e.preventDefault();
  };

  const handleDragEnd = (e, key) => {
    // Get the bounding rect of the container div
    const containerRect = containerRef.current.getBoundingClientRect();

    // Calculate the new position relative to the container
    const newX = (e.clientX - containerRect.left) / containerRect.width;
    const newY = (e.clientY - containerRect.top) / containerRect.height;

    // Update the position of the dragged item
    setNewDataWithLocation((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        x: newX, // New x relative to container
        y: newY, // New y relative to container
      },
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(newDataWithLocation).length ===
        selectedChallenge.placeHolders?.length - 1
      ) {
        const payload = [];
        console.log(placeholders);
        Object.entries(newDataWithLocation).forEach(([key, item]) => {
          console.log(key);
          payload.push({
            ...placeholders.find((item) => item.id === key),
            x: item.x,
            y: item.y,
          });
        });
        console.log(payload);
        await common_axios.patch("/placeholder/addPosition", payload);
        await fetchPlaceholders();
        toast.success("Data saved successfully");
      } else {
        toast.error(
          "Please tag all placeholders with locations before saving."
        );
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Failed to save data");
    }
  };
  return (
    <div className="w-[500px] p-4">
      <div className={`relative w-full`}>
        <label
          htmlFor={"img-path"}
          className="text-gray-500 dark:text-gray-500 text-sm"
        >
          Select challenge*
          <select
            id={"img-path"}
            className={clsx(
              "relative w-full capitalize mt-1 h-12 text-base font-normal text-gray-300 dark:text-gray-300 rounded bg-gray-50 dark:bg-container-500 border focus:dark:border-primary-300 focus-within:dark:ring-transparent",
              { "border-gray-700": true }
            )}
            value={selectedChallenge?.id}
            onChange={(e) => {
              console.log(e.target.value);
              setSelectedChallenge(
                challenges.find((item) => item.id === e.target.value)
              );
              setNewDataWithLocation({});
            }}
          >
            <option value="" aria-hidden="true" />
            <hr />
            {challenges.map((option) => (
              <option
                className="font-normal text-gray-500 dark:text-gray-200 py-4 capitalize"
                key={option.id}
                value={option.id}
              >
                {option.name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="w-full flex justify-center items-center">
        {selectedChallenge?.id && (
          <div
            ref={containerRef}
            className="bg-gray-900 w-[414px] h-[538px] max-h-[538px] mt-3 relative"
          >
            <div onClick={handleDivClick} className="h-full w-full">
              <div
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                class="absolute h-[382px] w-[250.88px] box-border"
              >
                <div
                  className="default-skin absolute"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: selectedChallenge?.skinOrder,
                  }}
                >
                  <img
                    className="h-auto max-w-[250.88px]"
                    src={IMG_PATH + selectedChallenge?.defaultSkin}
                    alt="Skin"
                    crossOrigin="anonymous"
                    style={{ verticalAlign: "middle" }}
                  />
                </div>
                {selectedChallenge.placeHolders?.map((item) => {
                  if (
                    selectedChallenge.backgroundPlaceholder === item.placeholder
                  ) {
                    return null;
                  }
                  const selectedCloset = closets[item.placeholder];
                  return (
                    <div
                      id="placeholder_1_shape"
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      style={{ zIndex: selectedCloset.order }}
                    >
                      <img
                        src={IMG_PATH + item.defaultItem}
                        alt={item.placeholder}
                        className="h-auto max-w-[250.88px] top[-1px]"
                        crossOrigin="anonymous"
                        style={{ verticalAlign: "middle" }}
                      />
                    </div>
                  );
                })}
              </div>
              {/* {selectedChallenge.placeHolders?.map((item) => {
                if (
                  selectedChallenge.backgroundPlaceholder !== item.placeholder
                ) {
                  return null;
                }
                return (
                  <div>
                    <img
                      src={process.env.REACT_APP_IMG_PATH + item.defaultItem}
                      alt={item.placeholder}
                      className="object-cover w-full max-h-[538px] object-bottom"
                      id="placeholder_bg_selected_image"
                    ></img>
                  </div>
                );
              })} */}
            </div>

            {Object.entries(newDataWithLocation).map(([key, item]) => {
              return (
                <div
                  draggable
                  key={key}
                  style={{
                    position: "absolute",
                    top: item.y * 538,
                    left: item.x * 414,
                    zIndex: 1000,
                  }}
                  onDragStart={(e) => handleDragStart(e, key)}
                  onDrag={(e) => handleDrag(e)}
                  onDragEnd={(e) => handleDragEnd(e, key)}
                >
                  <div className="h-6 w-6 rounded-full bg-primary-500" />
                </div>
              );
            })}
            {isOpen && (
              <div
                className="text-gray-500 dark:text-gray-200 text-xs"
                style={{
                  position: "absolute",
                  top: coordinates.y,
                  left: coordinates.x,
                }}
              >
                <select
                  onChange={handleOptionSelect}
                  className="p-1 dark:bg-container-300"
                >
                  <option value="">Select placeholder</option>
                  {selectedChallenge.placeHolders.map((currentPlaceholder) => {
                    return (
                      <option
                        className="font-normal text-gray-500 dark:text-gray-200"
                        key={currentPlaceholder.placeholder}
                        value={currentPlaceholder.placeholder}
                      >
                        {currentPlaceholder.placeholder}
                        {newDataWithLocation[currentPlaceholder.placeholder]
                          ? "- tagged"
                          : ""}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
        )}
      </div>
      {selectedChallenge?.id && (
        <div className="w-full flex justify-center h-12 gap-3 mt-3">
          <button
            disabled={loading}
            type="submit"
            onClick={handleSave}
            className="rounded shadow-xs cursor-pointer whitespace-nowrap relative px-4 py-2.5 font-semibold text-sm min-h-[2.5rem] min-w-[10.375rem] bg-primary-500 text-white border border-primary-500 hover:bg-primary-700 hover:border-primary-700 focus:ring-4 focus:ring-primary-100 disabled:bg-primary-200 disabled:border-primary-200"
          >
            {loading ? "loading..." : "Add"}
          </button>
        </div>
      )}
    </div>
  );
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import common_axios from "../utils/common_axios";

const DataContext = createContext({
  banners: [],
  closets: [],
  fetchBanners: null,
  fetchClosets: null,
});

export function DataProvider({ children }) {
  const [closets, setClosets] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [placeholders, setPlaceholders] = useState([]);

  const fetchClosets = async () => {
    try {
      const { data } = await common_axios.get(
        "/jsonData/closet?t=" + new Date().getTime()
      );
      setClosets(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchChallenges = async () => {
    try {
      const { data } = await common_axios.get(
        "/jsonData/challenge?t=" + new Date().getTime()
      );
      setChallenges(data.challenges);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPlaceholders = async () => {
    try {
      const { data } = await common_axios.get(
        "/jsonData/placeholder?t=" + new Date().getTime()
      );
      if (data.CustomisePlaceHolders) {
        setPlaceholders(data.CustomisePlaceHolders);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        fetchChallenges(),
        fetchClosets(),
        fetchPlaceholders(),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DataContext.Provider
      value={{
        closets,
        placeholders,
        challenges,
        fetchClosets,
        fetchPlaceholders,
        fetchChallenges,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
export const useData = () => useContext(DataContext);

import { useFormContext, get } from "react-hook-form";
import clsx from "clsx";

/**
 * A dropdown react component.
 * @param props - DropdownProps.
 * @returns - React element to capture user selection.
 */
export default function CustomSelect(props) {
  const { id, label, options, noMarginTop, validationObject, isDisabled } =
    props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className={`w-full ${!noMarginTop && "mt-4"}`}>
      <label
        htmlFor="dropdown-input"
        id={id}
        className="text-gray-500 dark:text-gray-500 text-sm"
      >
        {label}
        {label && validationObject?.required ? " *" : ""}
        <select
          disabled={isDisabled}
          id={id}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(id, validationObject)}
          className={clsx(
            "relative w-full capitalize mt-1 h-12 text-base font-normal text-gray-300 dark:text-gray-300 rounded bg-gray-50 dark:bg-container-500 border focus:dark:border-primary-300 focus-within:dark:ring-transparent",
            { "border-error-300": get(errors, id) },
            { "border-gray-700": !get(errors, id) }
          )}
        >
          <option value="" aria-hidden="true" />
          <hr />
          {options.map((option) => (
            <option
              className="font-normal text-gray-500 dark:text-gray-200 py-4 capitalize"
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </label>
      <p className="text-error-500 mt-1"> {get(errors, id)?.message}</p>
    </div>
  );
}

import Axios from "axios";
import { toast } from "react-toastify";

const origin = window.location.origin;
let baseURL = "http://localhost:3000/";
if (origin === "https://closet-stage.mysochials.com") {
  baseURL = "https://closet-stage.mysochials.com/";
}
if (origin === "https://closet.mysochials.com") {
  baseURL = "https://closet.mysochials.com/";
}
const common_axios = Axios.create({
  baseURL,
});

common_axios.interceptors.request.use(
  async (config) => {
    // Set common headers
    config.headers = {
      Accept: "application/json",
      ...config.headers, // Preserve existing headers
    };

    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

common_axios.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle responses with error status codes
    if (error.response && error.response.status === 401) {
      // Show alert for unauthorized errors
      toast.error("Unauthorized: Please update the cookie using the icon.");
    }

    // You can add other status code handling here if needed
    return Promise.reject(error);
  }
);

export default common_axios;

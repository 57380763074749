import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import clsx from "clsx";

registerLocale("en", en);

/**
 * A dropdown react component.
 * @param props - DropdownProps.
 * @returns - React element to capture user selection.
 */
export default function CustomDatePicker(props) {
  const {
    label,
    noMarginTop,
    id,
    validationObject,
    control,
    disabled,
    defaultValue,
  } = props;
  const { setValue } = useFormContext();
  useEffect(() => {
    if (defaultValue) {
      setValue(id, defaultValue);
    }
  }, [defaultValue, id, setValue]);
  return (
    <div className={`w-full ${!noMarginTop ? "mt-4" : "mt-1"}`}>
      {label && (
        <div className="text-gray-500 dark:text-gray-500 text-sm">
          {label}
          {validationObject?.required ? " *" : ""}
        </div>
      )}
      <Controller
        name={id}
        rules={validationObject}
        control={control}
        defaultValue={defaultValue || null}
        disabled={disabled}
        render={({ field, fieldState: { error } }) => (
          <>
            <DatePicker
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              disabled={disabled}
              onChange={(date) => {
                field.onChange(date);
              }}
              selected={field.value || null}
              locale="en"
              className={clsx(
                "w-full block px-3.5 text-base h-12 font-normal dark:text-gray-300 placeholder-gray-400 border rounded dark:bg-container-500 focus:dark:border-primary-300 focus-within:dark:ring-transparent",
                { "border-error-300": error },
                { "border-gray-700": !error },
                { "mt-1": !!label }
              )}
              placeholderText={label}
              showTimeSelect
              dateFormat="dd/MM/yyy, hh:mm"
            />
            <p className="text-error-500 mt-1"> {error?.message}</p>
          </>
        )}
      />
    </div>
  );
}

/**
 * Function to return the Tab's CSS classes based on Type.
 * @param type - Tab Type.
 * @returns - Tab's CSS classes that styles the tabs component as per type.
 */
const getTabSizeFromType = (type) => {
  let tabCssClasses = "";

  if (type === "fit") {
    tabCssClasses = "w-fit";
  }
  if (type === "full-width") {
    tabCssClasses = "flex-grow md:w-60";
  }

  return tabCssClasses;
};

/**
 * Gets hover state classes for selected tab based on selected tab background type.
 * @param selectedTabTheme - Seleted tab's background theme.
 * @returns - Selected Tab's hover state CSS classes.
 */
const getHoverClassesBasedOnTheme = (selectedTabTheme) => {
  let hoverStateCSS = "";

  if (selectedTabTheme === "dark") {
    hoverStateCSS = "dark:hover:bg-container-500";
  } else if (selectedTabTheme === "light") {
    hoverStateCSS = "dark:hover:bg-background-500";
  }

  return hoverStateCSS;
};

/**
 * Gets selected state classes for selected tab.
 * @param selectedTabTheme - Seleted tab's background theme.
 * @returns - Selected Tab's hover state CSS classes.
 */
const getSelectedStateClasses = (selectedTabTheme) => {
  let hoverStateCSS =
    "text-primary-500 bg-primary-100 border-b-2 border-b-primary-500";

  if (selectedTabTheme === "dark") {
    hoverStateCSS += " dark:bg-container-500";
  } else if (selectedTabTheme === "light") {
    hoverStateCSS += " dark:bg-background-500";
  }

  return hoverStateCSS;
};

/**
 * A Tabs component renders set of tab.
 * @param props - Properties of the Tabs Component.
 * @returns - HTML for set of Tab.
 */
export default function Tabs(props) {
  const {
    selectedTab,
    onTabClick,
    tabs,
    tabType,
    selectedTabBackground = "dark",
    fontStyle,
  } = props;

  return (
    <div className="flex text-gray-800 dark:text-gray-400">
      {tabs.map(({ id, name }) => (
        <div
          key={id}
          role="button"
          tabIndex={0}
          onClick={() => onTabClick(id)}
          onKeyDown={() => onTabClick(id)}
          className={`p-3 text-center ${
            fontStyle || "text-sm font-semibold"
          }  flex justify-center items-center ${getTabSizeFromType(tabType)} ${
            id === selectedTab
              ? getSelectedStateClasses(selectedTabBackground)
              : ""
          } hover:text-primary-500 hover:bg-primary-100 ${getHoverClassesBasedOnTheme(
            selectedTabBackground
          )} hover:border-b-2 hover:border-b-primary-500`}
        >
          {name}
        </div>
      ))}
    </div>
  );
}

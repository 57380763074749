import React, { useRef, useState } from "react";
import { useFormContext, get } from "react-hook-form";
import clsx from "clsx";
import common_axios from "../utils/common_axios";

/**
 * A custom file upload component for use with React Hook Form.
 * @param props - Component props.
 * @returns - React element for file upload input.
 */
export default function CustomFileUpload(props) {
  const {
    label,
    isDisabled = false,
    idname,
    autoComplete,
    validationObject,
    isHidden,
    placeholder,
    autoFocus,
    uploadEndpoint,
    setFileUrl,
    mime,
  } = props;

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const ref = useRef(null);
  // Handle file upload
  const handleFileUpload = async (file) => {
    if (!file) return; // Return if no file is selected

    const formData = new FormData();
    formData.append("file", file);

    setIsUploading(true);

    try {
      const response = await common_axios.post(
        `/upload/${encodeURIComponent(uploadEndpoint)}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const fileUrl = response.data.file;
      setValue(idname, fileUrl); // Set the file URL in the form
      setFileUrl && setFileUrl(fileUrl); // Optional: Set URL in parent component if needed

      setUploadError("");
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError("Error uploading file");
    } finally {
      setIsUploading(false);
    }
  };
  const uploaderIdName = idname + "file";
  const value = watch(idname);
  return (
    <div
      style={{ display: isHidden ? "none" : "block" }}
      className={`relative w-full ${!props.noMarginTop && "mt-4"}`}
    >
      {label && (
        <label
          htmlFor={idname}
          className="text-gray-500 dark:text-gray-500 text-sm"
        >
          {label}
          {validationObject?.required ? " *" : ""}
        </label>
      )}
      <div className="relative">
        <input
          type="file"
          accept={mime}
          disabled={isDisabled}
          autoComplete={autoComplete}
          placeholder={placeholder}
          id={uploaderIdName}
          autoFocus={autoFocus}
          // Handle file input change
          {...register(uploaderIdName, {
            onChange: (e) => {
              handleFileUpload(e.target.files[0]);
            },
          })}
          ref={ref}
          className={clsx(
            "w-full hidden py-1.5 px-3.5 text-base font-normal dark:text-gray-300 placeholder-gray-400 border rounded bg-gray-50 dark:bg-container-500 focus:dark:border-primary-300 focus-within:dark:ring-transparent",
            { "dark:text-gray-500": isDisabled },
            { "mt-1": !!label }
          )}
        />
        <button
          type="button"
          onClick={() => {
            console.log(ref);
            ref.current && ref.current.click();
          }}
          className={clsx(
            "w-full block px-3.5 h-12 mt-1 min-w-[200px] text-base font-normal dark:text-gray-300 placeholder-gray-400 border rounded bg-gray-50 dark:bg-container-500 focus:dark:border-primary-300 focus-within:dark:ring-transparent",
            { "border-error-300": get(errors, idname) },
            { "border-gray-700": !get(errors, idname) }
          )}
        >
          {value ? (
            value
          ) : (
            <span className="material-icons-outlined">upload</span>
          )}
          <input
            type={"hidden"}
            className={clsx(
              "w-full block px-3.5 h-12 text-base font-normal dark:text-gray-300 placeholder-gray-400 border rounded bg-gray-50 dark:bg-container-500 focus:dark:border-primary-300 focus-within:dark:ring-transparent"
            )}
            {...register(idname, {
              required: "File URL is required after upload",
            })}
          />
        </button>
        {isUploading && (
          <p className="text-primary-500 text-sm">Uploading...</p>
        )}
        {uploadError && <p className="text-error-500 text-sm">{uploadError}</p>}
      </div>
      <p className="text-error-500 mt-1">
        {get(errors, uploaderIdName)?.message}
      </p>
      <p className="text-error-500 mt-1">{get(errors, idname)?.message}</p>
    </div>
  );
}

import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CustomSelect from "../controls/select";
import CustomFileUpload from "../controls/fileUploader";
import { useData } from "../context/data";
import common_axios from "../utils/common_axios";
import SidePanelContainer from "../controls/sidepanel";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function CreatePlaceholder() {
  const { closets, placeholders, fetchPlaceholders } = useData();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get("id");

  const methods = useForm({
    mode: "all",
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (id) {
      const placeholder = placeholders.find((item) => item.id === id);
      console.log(placeholder);
      if (placeholder) {
        reset(placeholder);
      }
    }
  }, [placeholders, id, reset]);

  const handleSubmitForm = async (formData) => {
    try {
      setLoading(true);
      const payload = {
        closetType: formData.closetType,
        icon: formData.icon,
      };
      await common_axios.post("/placeholder", payload);
      await fetchPlaceholders();
      toast.success("Placeholder added");
      setLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setLoading(false);
      toast.error("Failed to add placeholder");
    }
  };

  const onDelete = async (id) => {
    try {
      const confirm = window.confirm("Are you sure?");
      if (confirm) {
        await common_axios.delete(`/placeholder/${id}`);
        await fetchPlaceholders();
        toast.success("Deleted successfully");
      }
    } catch (e) {
      console.log(e);
      toast.error("An error occured!");
    }
  };

  const placeholderMap = placeholders.map((item) => item.closetType);

  return (
    <div>
      <div className="flex justify-end items-end p-3">
        <button
          onClick={() => setModal(true)}
          className="material-icons-outlined text-gray-300"
        >
          menu
        </button>
      </div>
      <div className="flex justify-between px-3 md:px-8 py-5">
        <FormProvider {...methods}>
          <form
            id="apiForm"
            className="flex flex-1 max-w-72 flex-wrap  gap-3 flex-col"
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            {!id ? (
              <div>
                <CustomSelect
                  label="Closet Type"
                  noMarginTop
                  validationObject={{ required: "Closet Type is required" }}
                  id="closetType"
                  options={Object.keys(closets)
                    .filter((item) => !placeholderMap.includes(item))
                    .map((item) => ({
                      label: item,
                      value: item,
                    }))}
                />
              </div>
            ) : (
              <div className="text-white">Closet ID: {id}</div>
            )}
            <div className="">
              <CustomFileUpload
                mime=".svg, image/svg+xml"
                label="Icon"
                noMarginTop
                idname="icon"
                uploadEndpoint="placeholder"
                validationObject={{ required: "Icon is required" }}
              />
            </div>
            <div className="w-full flex justify-end h-12 gap-3">
              <button
                disabled={loading}
                type="submit"
                className="rounded shadow-xs cursor-pointer whitespace-nowrap relative px-4 py-2.5 font-semibold text-sm min-h-[2.5rem] min-w-[10.375rem] bg-primary-500 text-white border border-primary-500 hover:bg-primary-700 hover:border-primary-700 focus:ring-4 focus:ring-primary-100 disabled:bg-primary-200 disabled:border-primary-200"
              >
                {loading ? "loading..." : "Add"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      {modal && (
        <SidePanelContainer closeModal={() => setModal(false)}>
          <div className="text-gray-300 text-base">Placeholders</div>
          <div className="mt-8">
            {placeholders.map((item) => (
              <div
                key={item.id}
                className="mt-3 rounded bg-container-600 px-3 py-2 border border-gray-700 flex justify-between items-center"
              >
                <div className="text-gray-300">{item.closetType}</div>
                <div className="flex">
                  <button
                    onClick={() => {
                      navigate(`/create-placeholder?id=${item.id}`);
                      setModal(false);
                    }}
                    className="material-icons-outlined text-warning-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
                  >
                    edit
                  </button>
                  <button
                    onClick={() => onDelete(item.id)}
                    className="material-icons-outlined text-error-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
                  >
                    delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </SidePanelContainer>
      )}
    </div>
  );
}

import { useFormContext, get } from "react-hook-form";
import clsx from "clsx";

/**
 * A common Input control component.
 * @param props - Input props.
 * @returns - Returns a react element for input text.
 */
export default function CustomInputBox(props) {
  const {
    label,
    isDisabled = false,
    actionElement,
    noMarginTop,
    type = "text",
    idname,
    autoComplete,
    validationObject,
    isHidden,
    min,
    max,
    height,
    placeholder,
    autoFocus,
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div
      style={{ display: isHidden ? "none" : "block" }}
      className={`relative w-full ${!noMarginTop && "mt-4"}`}
    >
      {label && (
        <label
          htmlFor={idname}
          className="text-gray-500 dark:text-gray-500 text-sm"
        >
          {label}
          {validationObject?.required ? " *" : ""}
        </label>
      )}
      <div className="relative">
        <input
          type={type}
          step={0}
          max={max}
          min={min}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          disabled={isDisabled}
          autoComplete={autoComplete}
          placeholder={placeholder}
          id={idname}
          style={{ height }}
          // eslint-disable-next-line
          {...register(idname, validationObject)}
          className={clsx(
            "w-full block px-3.5 h-12 text-base font-normal dark:text-gray-300 placeholder-gray-400 border rounded bg-gray-50 dark:bg-container-500 focus:dark:border-primary-300 focus-within:dark:ring-transparent",
            { "dark:text-gray-500": isDisabled },
            { "mt-1": !!label },
            { "border-error-300": get(errors, idname) },
            { "border-gray-700": !get(errors, idname) }
          )}
        />
        {actionElement}
      </div>
      <p className="text-error-500 mt-1"> {get(errors, idname)?.message}</p>
    </div>
  );
}

import { useEffect, useRef } from "react";

/**
 * Side Panel Container component for showcasing content on the side panel. Content can be rendered inside this panel as children.
 * @param props - React Props.
 * @returns - Side Panel Container Component.
 */
export default function SidePanelContainer(props) {
  const { isOpen, closeModal, children, width } = props;
  const dialogRef = useRef(null);

  useEffect(() => {
    if (isOpen) dialogRef.current?.showModal();
    else dialogRef.current?.close();
  }, [isOpen]);

  return (
    <div>
      <div className="fixed top-0 right-0 w-full h-screen bg-background-600/5 z-50">
        <div
          className={`p-5 pb-20 ${
            width || "w-full sm:w-1/2 lg:w-1/3 2xl:w-1/4"
          } fixed h-screen right-0 top-0 z-10 overflow-auto bg-background-900`}
        >
          <button
            type="button"
            onClick={closeModal}
            className="cursor-pointer material-icons-outlined flex items-center m-2 absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            close
          </button>
          {children}
        </div>
      </div>
    </div>
  );
}

import { Controller } from "react-hook-form";
import Select from "react-select";

export default function CustomMultiSelect(props) {
  const { label, options, noMarginTop, idname, validationObject, control } =
    props;
  return (
    <div className={`w-full ${!noMarginTop && "mt-4"}`}>
      <div className="text-gray-500 dark:text-gray-500 text-sm">
        {label}
        {validationObject?.required ? " *" : ""}
      </div>
      <Controller
        name={idname}
        control={control}
        rules={validationObject}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              options={options}
              styles={{
                control: (styles) => ({
                  ...styles,
                  background: "#0d0d14",
                  marginTop: 8,
                  minHeight: 48,
                  minWidth: 200,
                  border: error ? "1px solid #FDA29B" : "1px solid #344054",
                  boxShadow: "0 !important",
                  "&:hover": {
                    border: "1px solid #84ADFF !important",
                  },
                }),
                menuList: (styles) => ({
                  ...styles,
                  background: "#12151f",
                  color: "#667085",
                }),
                option: (styles) => ({
                  ...styles,
                  background: "#12151f",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#1a1d2c",
                    color: "#D0D5DD",
                  },
                }),
                multiValue: (styles) => ({
                  ...styles,
                  background: "#1D2939",
                  color: "#B2CCFF",
                }),
                multiValueLabel: (styles) => ({
                  ...styles,
                  color: "#B2CCFF",
                }),
              }}
              className="bg-container-500"
              isMulti
            />
            <p className="text-error-500 mt-1"> {error?.message}</p>
          </>
        )}
      />
    </div>
  );
}

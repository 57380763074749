import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CustomInputBox from "../controls/input";
import CustomSelect from "../controls/select";
import CustomFileUpload from "../controls/fileUploader";
import { v4 as uuidv4 } from "uuid";
import common_axios from "../utils/common_axios";
import { useData } from "../context/data";
import SidePanelContainer from "../controls/sidepanel";
import { useNavigate, useSearchParams } from "react-router-dom";

const REWARD_TYPES = [
  { value: 0, label: "Cash" },
  { value: 1, label: "Coin" },
  { value: 2, label: "XP" },
];

const CATEGORIES = [
  { value: "Normal", label: "Normal" },
  { value: "Common", label: "Common" },
  { value: "Special", label: "Special" },
];

function nameToUsername(name) {
  return name?.trim().toLowerCase().replace(/\s+/g, "_");
}

function InnerArray({ nestIndex, control, path }) {
  const {
    fields: innerFields,
    append: appendInner,
    remove: removeInner,
  } = useFieldArray({
    control,
    name: `items.${nestIndex}.colors`, // Path to inner array
  });

  return (
    <div className="border border-gray-700 py-3 px-5 mt-3 rounded-md">
      <div className="text-gray-500 dark:text-gray-500 text-sm flex items-center gap-2 mb-3">
        <div>Colors</div>
        <div
          onClick={() => {
            appendInner({
              id: uuidv4(),
              code: "",
              asset: "",
              icon: "",
            });
          }}
          role="button"
          className="material-icons-outlined text-gray-300 text-lg bg-primary-500 h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
        >
          add
        </div>
      </div>
      {innerFields.map((innerField, innerIndex) => (
        <div className="flex justify-between" key={innerField.id}>
          <div className="flex gap-3">
            <div>
              <CustomInputBox
                label="Color code"
                idname={`items.${nestIndex}.colors.${innerIndex}.code`}
                noMarginTop
                type="color"
                validationObject={{
                  required: "Color code is required",
                }}
              />
            </div>
            <div>
              <CustomFileUpload
                mime=".svg, image/svg+xml"
                label="Asset"
                idname={`items.${nestIndex}.colors.${innerIndex}.asset`}
                noMarginTop
                uploadEndpoint={path}
                validationObject={{ required: "Icon is required" }}
              />
            </div>
            <div>
              <CustomFileUpload
                mime=".svg, image/svg+xml"
                label="Icon"
                idname={`items.${nestIndex}.colors.${innerIndex}.icon`}
                noMarginTop
                uploadEndpoint={path + "/thumbnail"}
                validationObject={{ required: "Icon is required" }}
              />
            </div>
          </div>
          <div
            onClick={() => {
              removeInner(innerIndex);
            }}
            role="button"
            className="material-icons-outlined text-error-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
          >
            delete
          </div>
        </div>
      ))}
    </div>
  );
}

export default function CreateCloset() {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { fetchClosets, closets } = useData();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get("id");
  const methods = useForm({
    mode: "all",
    defaultValues: {
      items: [], // Initial array of items
    },
  });

  const { handleSubmit, control, watch, reset } = methods;

  useEffect(() => {
    if (id && Object.keys(closets).length > 0) {
      const closet = closets[id];
      if (closet) {
        reset(closet);
      }
    }
  }, [closets, id, reset]);

  const handleEdit = async (formData) => {
    try {
      setLoading(true);
      console.log(formData);
      await common_axios.patch(`/closet/${id}`, formData);
      await fetchClosets();
      toast.success("Closet updated");
      setLoading(false);
    } catch (e) {}
  };

  const handleSubmitForm = async (formData) => {
    try {
      setLoading(true);
      if (!formData.items.length) {
        toast.error("Items are missing!");
        setLoading(false);
        return;
      }
      const payload = {
        title: formData.title,
        order: Number(formData.order),
        icon: formData.icon,
        items: formData.items.map((item) => ({
          id: item.id,
          category: item.category,
          name: item.name,
          isStore: Boolean(item.isStore),
          star: Number(item.star),
          costAmount: Number(item.costAmount),
          costType: Number(item.costType),
          colors: item.colors?.map((color) => ({
            asset: color.asset,
            icon: color.icon,
            id: color.id,
            code: color.code,
          })),
        })),
      };
      if (id) {
        await handleEdit(payload);
        return;
      }
      await common_axios.post("/closet", payload);
      await fetchClosets();
      toast.success("Closet added");
      setLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      toast.error("Failed to add closet");
      setLoading(false);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items", // Name of the array field in your form
  });

  const generatePathByName = (id) => {
    const value = watch(id);
    return `Closet/${nameToUsername(value)}`;
  };

  const onDelete = async (id) => {
    try {
      const confirm = window.confirm("Are you sure?");
      if (confirm) {
        await common_axios.delete(`/closet/${id}`);
        await fetchClosets();
        toast.success("Deleted successfully");
      }
    } catch (e) {
      console.log(e);
      toast.error("An error occured!");
    }
  };

  return (
    <div>
      <div className="flex justify-end items-end p-3">
        <button
          onClick={() => setModal(true)}
          className="material-icons-outlined text-gray-300"
        >
          menu
        </button>
      </div>
      <FormProvider {...methods}>
        <form
          id="apiForm"
          className="flex flex-1 flex-wrap px-3 md:px-8 py-5 gap-3"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div>
            <CustomInputBox
              label="Title"
              noMarginTop
              idname="title"
              type="text"
              validationObject={{ required: "Title is required" }}
            />
          </div>
          <div>
            <CustomInputBox
              label="Order"
              noMarginTop
              idname="order"
              type="number"
              validationObject={{ required: "Order is required" }}
            />
          </div>
          <div>
            <CustomFileUpload
              mime=".svg, image/svg+xml"
              label="Icon"
              idname="icon"
              noMarginTop
              uploadEndpoint={generatePathByName("title") + "/thumbnail"}
              validationObject={{ required: "Icon is required" }}
            />
          </div>
          <div className="w-full">
            <div className="text-gray-500 dark:text-gray-500 text-sm flex items-center gap-2">
              <div>Items</div>
              <div
                onClick={() => {
                  append({
                    id: uuidv4(),
                    star: "",
                    category: "",
                    name: "",
                    isStore: true,
                    costType: "",
                    costAmount: "",
                    colors: [],
                  });
                }}
                role="button"
                className="material-icons-outlined text-gray-300 text-lg bg-primary-500 h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
              >
                add
              </div>
            </div>
            <div>
              {fields.map((item, index) => (
                <div
                  key={item.id}
                  className="bg-container-800 py-2 px-4 rounded-md mt-4 "
                >
                  <div className="flex justify-between items-center">
                    <div className="flex gap-3">
                      <div>
                        <CustomInputBox
                          label="Name"
                          idname={`items.${index}.name`}
                          noMarginTop
                          type="text"
                          validationObject={{
                            required: "Name is required",
                          }}
                        />
                      </div>
                      <div>
                        <CustomInputBox
                          label="Star"
                          idname={`items.${index}.star`}
                          noMarginTop
                          type="number"
                          validationObject={{
                            required: "Star is required",
                          }}
                        />
                      </div>
                      <div>
                        <CustomSelect
                          label={`Category`}
                          noMarginTop
                          validationObject={{
                            required: `Category is required`,
                          }}
                          id={`items.${index}.category`}
                          options={CATEGORIES}
                        />
                      </div>
                      <div>
                        <CustomSelect
                          label={`Is store`}
                          noMarginTop
                          validationObject={{
                            required: `Is store is required`,
                          }}
                          id={`items.${index}.isStore`}
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                        />
                      </div>

                      <div>
                        <CustomSelect
                          label={`Cost type`}
                          noMarginTop
                          validationObject={{
                            required: `Cost type is required`,
                          }}
                          id={`items.${index}.costType`}
                          options={REWARD_TYPES}
                        />
                      </div>
                      <div>
                        <CustomInputBox
                          label="Cost amount"
                          idname={`items.${index}.costAmount`}
                          noMarginTop
                          type="number"
                          validationObject={{
                            required: "Cost amount is required",
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex px-2">
                      <div
                        onClick={() => {
                          append({
                            ...fields[index],
                            id: uuidv4(), // Generate a new unique ID
                            colors: [],
                          });
                        }}
                        role="button"
                        className="material-icons-outlined text-primary-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
                      >
                        copy
                      </div>
                      <div
                        onClick={() => {
                          remove(index);
                        }}
                        role="button"
                        className="material-icons-outlined text-error-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
                      >
                        delete
                      </div>
                    </div>
                  </div>
                  <InnerArray
                    path={generatePathByName(`items.${index}.name`)}
                    nestIndex={index}
                    control={control}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex justify-end h-12 gap-3">
            <button
              disabled={loading}
              type="submit"
              className="rounded shadow-xs cursor-pointer whitespace-nowrap relative px-4 py-2.5 font-semibold text-sm min-h-[2.5rem] min-w-[10.375rem] bg-primary-500 text-white border border-primary-500 hover:bg-primary-700 hover:border-primary-700 focus:ring-4 focus:ring-primary-100 disabled:bg-primary-200 disabled:border-primary-200"
            >
              {loading ? "loading..." : "Add"}
            </button>
          </div>
        </form>
      </FormProvider>
      {modal && (
        <SidePanelContainer closeModal={() => setModal(false)}>
          <div className="text-gray-300 text-base">Closets</div>
          <div className="mt-8">
            {Object.keys(closets).map((key) => (
              <div
                key={key}
                className="mt-3 rounded bg-container-600 px-3 py-2 border border-gray-700 flex justify-between items-center"
              >
                <div className="text-gray-300">{key}</div>
                <div className="flex">
                  <button
                    onClick={() => {
                      navigate(`/create-closet?id=${key}`);
                      setModal(false);
                    }}
                    className="material-icons-outlined text-warning-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
                  >
                    edit
                  </button>
                  <button
                    onClick={() => onDelete(key)}
                    className="material-icons-outlined text-error-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
                  >
                    delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </SidePanelContainer>
      )}
    </div>
  );
}

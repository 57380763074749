import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import CreateChallenge from "./pages/create-challenge.page";
import CreateCloset from "./pages/create-closet.page";
import Layout from "./layout";
import { DataProvider } from "./context/data";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatePlaceholder from "./pages/create-placeholder";
import MarkCoordinates from "./pages/mark-coordinates";

function App() {
  return (
    <DataProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/create-challenge" />} />
            <Route path="/create-closet" element={<CreateCloset />} />
            <Route path="/create-challenge" element={<CreateChallenge />} />
            <Route path="/create-placeholder" element={<CreatePlaceholder />} />
            <Route path="/mark-coordinates" element={<MarkCoordinates />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="dark"
      />
    </DataProvider>
  );
}

export default App;

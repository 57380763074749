import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CustomInputBox from "../controls/input";
import CustomDatePicker from "../controls/datePicker";
import CustomSelect from "../controls/select";
import CustomFileUpload from "../controls/fileUploader";
import CustomMultiSelect from "../controls/multiselect";
import { useData } from "../context/data";
import common_axios from "../utils/common_axios";
import SidePanelContainer from "../controls/sidepanel";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const REWARD_TYPES = [
  { value: 0, label: "Cash" },
  { value: 1, label: "Coin" },
  { value: 2, label: "XP" },
  { value: 3, label: "Closet Item" },
];

export default function CreateChallenge() {
  const { placeholders, closets, fetchChallenges, challenges } = useData();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [params] = useSearchParams();
  const id = params.get("id");

  const methods = useForm({
    mode: "all",
  });

  const { handleSubmit, control, watch, reset } = methods;

  const getOptionsByPlaceholder = (value) => {
    return (
      closets[value]?.items?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []
    );
  };

  useEffect(() => {
    if (id && challenges?.length > 0) {
      const challenge = challenges.find((item) => item.id === id);
      if (challenge) {
        const placeHolders = challenge.placeHolders?.map((placeholder) => {
          const pl = placeholders?.find(
            (item) => item.id === placeholder.placeholder
          );
          return {
            label: pl.closetType,
            value: pl.id,
          };
        });
        const formObject = {
          ...challenge,
          placeHolders,
        };
        placeHolders.forEach((item) => {
          formObject[`placeholder_available_${item.value}`] =
            getOptionsByPlaceholder(item.label);
          formObject[`placeholder_default_${item.value}`] =
            challenge.placeHolders?.find((item) => item.defaultItem);
        });
        console.log(formObject);
        reset(formObject);
      }
    }
  }, [challenges, id, reset, placeholders, closets]);

  const handleEdit = (formData) => {
    console.log(formData);
  };

  const generateVotingPeriods = (startTime, durationInMinutes, numOfTimes) => {
    const periods = [];
    let startMoment = moment.unix(startTime); // Parse startTime into a moment object

    for (let i = 0; i < numOfTimes; i++) {
      const startUnix = startMoment.unix(); // Get start time in Unix format
      const endMoment = startMoment.clone().add(durationInMinutes, "minutes"); // Calculate end time
      const endUnix = endMoment.unix(); // Get end time in Unix format

      // Create the period object
      periods.push({
        id: `voting_${startUnix}_${i + 1}`,
        startTime: startUnix,
        endTime: endUnix,
      });

      // Move to the next period's start time
      startMoment = endMoment;
    }

    return periods;
  };

  const handleSubmitForm = async (formData) => {
    if (id) {
      handleEdit(formData);
      return;
    }
    try {
      setLoading(true);
      const avaiableclosets = formData.placeHolders.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.label]: formData[`placeholder_available_${curr.value}`]?.map(
            (item) => item.value
          ),
        };
      }, {});

      const defaultItem = (placeholder, id) => {
        const item = closets[placeholder].items?.find((item) => item.id === id);
        if (item.colors?.length) {
          return item.colors[0].asset;
        }
        toast.error("Selected placeholder doesn't have any colors in closet");
        return null;
      };
      const startTime = moment(formData.startTime).unix();
      const duration = Number(formData.duration);
      const numOfTimes = Number(formData.numOfTimes);
      const periods = generateVotingPeriods(startTime, duration, numOfTimes);
      console.log(periods);
      const payload = {
        name: formData.name,
        description: formData.description,
        startTime,
        duration,
        numOfTimes,
        rewardType: Number(formData.rewardType),
        rewardAmount: Number(formData.rewardAmount),
        minimumStarsToEnterLive: Number(formData.minimumStarsToEnterLive),
        banner: formData.banner,
        defaultSkin: formData.defaultSkin,
        skinOrder: Number(formData.skinOrder),
        backgroundPlaceholder: formData.backgroundPlaceholder,
        placeHolders: formData.placeHolders?.map((item) => ({
          placeholder: item.value,
          defaultItem: defaultItem(
            item.label,
            formData[`placeholder_default_${item.value}`]
          ),
        })),
        periods,
        avaiableclosets,
      };
      await common_axios.post("/challenge", payload);
      await fetchChallenges();
      toast.success("Challenge created");
      setLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      if (e.response?.data?.errors?.length) {
        toast.error(e.response?.data?.errors[0]);
      } else {
        toast.error("Failed to create challenge");
      }

      setLoading(false);
    }
  };

  const selectedPlaceholders = watch("placeHolders") || [];

  const onDelete = async (id) => {
    try {
      const confirm = window.confirm("Are you sure?");
      if (confirm) {
        await common_axios.delete(`/challenge/${id}`);
        await fetchChallenges();
        toast.success("Deleted successfully");
      }
    } catch (e) {
      console.log(e);
      toast.error("An error occured!");
    }
  };

  return (
    <div>
      <div className="flex justify-end items-end p-3">
        <button
          onClick={() => setModal(true)}
          className="material-icons-outlined text-gray-300"
        >
          menu
        </button>
      </div>
      <FormProvider {...methods}>
        <form
          id="apiForm"
          className="flex flex-1 flex-wrap px-3 md:px-8 py-5 gap-3"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div>
            <CustomInputBox
              label="Name"
              noMarginTop
              idname="name"
              type="text"
              validationObject={{ required: "Name is required" }}
            />
          </div>
          <div>
            <CustomInputBox
              label="Description"
              noMarginTop
              idname="description"
              type="text"
              validationObject={{ required: "Description is required" }}
            />
          </div>

          <div>
            <CustomDatePicker
              label="Start time"
              noMarginTop
              id="startTime"
              validationObject={{ required: "Start time is required" }}
              control={control}
              defaultValue={methods.getValues("startTime")}
            />
          </div>
          <div>
            <CustomInputBox
              label="Duration (in mins)"
              noMarginTop
              idname="duration"
              type="number"
              validationObject={{ required: "Duration is required" }}
            />
          </div>
          <div>
            <CustomInputBox
              label="Will repeat for?"
              noMarginTop
              idname="numOfTimes"
              type="number"
              validationObject={{ required: "Will repeat for is required" }}
            />
          </div>
          <div>
            <CustomSelect
              label="Reward Type"
              noMarginTop
              validationObject={{ required: "Reward Type is required" }}
              id="rewardType"
              options={REWARD_TYPES}
            />
          </div>
          <div>
            <CustomInputBox
              label="Reward Amount"
              noMarginTop
              idname="rewardAmount"
              type="number"
              validationObject={{ required: "Reward Amount is required" }}
            />
          </div>
          <div>
            <CustomInputBox
              label="Minimum Stars To Enter Live"
              idname="minimumStarsToEnterLive"
              noMarginTop
              type="number"
              validationObject={{
                required: "Minimum Stars To Enter Live is required",
              }}
            />
          </div>
          <div>
            <CustomFileUpload
              mime=".svg, image/svg+xml"
              label="Banner"
              noMarginTop
              idname="banner"
              uploadEndpoint="banner"
              validationObject={{ required: "Banner is required" }}
            />
          </div>
          <div>
            <CustomFileUpload
              mime=".svg, image/svg+xml"
              label="Default Skin"
              idname="defaultSkin"
              noMarginTop
              uploadEndpoint="skin"
              validationObject={{ required: "Default Skin is required" }}
            />
          </div>
          <div>
            <CustomInputBox
              label="Skin Order"
              idname="skinOrder"
              noMarginTop
              type="number"
              validationObject={{
                required: "Skin Order is required",
              }}
            />
          </div>
          <div>
            <CustomMultiSelect
              label="Placeholders"
              idname="placeHolders"
              noMarginTop
              options={placeholders.map((item) => ({
                label: item.closetType,
                value: item.id,
              }))}
              validationObject={{ required: "Placeholders are required" }}
            />
          </div>
          <div>
            <CustomSelect
              label="Background placeholder"
              noMarginTop
              validationObject={{
                required: "Background placeholder is required",
              }}
              id="backgroundPlaceholder"
              options={selectedPlaceholders?.map((item) => item)}
            />
          </div>
          {selectedPlaceholders.length !== 0 && (
            <div className="w-full">
              {selectedPlaceholders.map((item) => (
                <div className="w-full flex gap-3" key={item.value}>
                  {!id && (
                    <div>
                      <CustomSelect
                        label={`Default item for ${item.label}`}
                        noMarginTop
                        validationObject={{
                          required: `${item.label} is required`,
                        }}
                        id={`placeholder_default_${item.value}`}
                        options={getOptionsByPlaceholder(item.label)}
                      />
                    </div>
                  )}
                  <div>
                    <CustomMultiSelect
                      noMarginTop
                      validationObject={{
                        required: `${item.label} is required`,
                      }}
                      options={getOptionsByPlaceholder(item.label)}
                      label={`Available item for ${item.label}`}
                      idname={`placeholder_available_${item.value}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="w-full flex justify-end h-12 gap-3">
            <button
              disabled={loading}
              type="submit"
              className="rounded shadow-xs cursor-pointer whitespace-nowrap relative px-4 py-2.5 font-semibold text-sm min-h-[2.5rem] min-w-[10.375rem] bg-primary-500 text-white border border-primary-500 hover:bg-primary-700 hover:border-primary-700 focus:ring-4 focus:ring-primary-100 disabled:bg-primary-200 disabled:border-primary-200"
            >
              {loading ? "loading..." : "Add"}
            </button>
          </div>
        </form>
      </FormProvider>
      {modal && (
        <SidePanelContainer closeModal={() => setModal(false)}>
          <div className="text-gray-300 text-base">Challenges</div>
          <div className="mt-8">
            {challenges.map((challenge) => (
              <div
                key={challenge.id}
                className="mt-3 rounded bg-container-600 px-3 py-2 border border-gray-700 flex justify-between items-center"
              >
                <div className="text-gray-300">{challenge.name}</div>
                <div className="flex">
                  {/* <button
                    onClick={() => {
                      navigate(`/create-challenge?id=${challenge.id}`);
                      setModal(false);
                    }}
                    className="material-icons-outlined text-warning-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
                  >
                    edit
                  </button> */}
                  <button
                    onClick={() => onDelete(challenge.id)}
                    className="material-icons-outlined text-error-400 text-lg h-10 w-10 rounded-full cursor-pointer flex justify-center items-center"
                  >
                    delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </SidePanelContainer>
      )}
    </div>
  );
}
